export const environment = {
  production: true,
  keycloak: {
    issuer: 'https://login2.staging.trigonos.lumoe.io/auth/',
    realm: 'Trigonos',
    clientId: 'alpine-monitoring-app',
  },
  basemapURL: 'https://mapsneu.wien.gv.at/basemapneu/1.0.0/WMTSCapabilities.xml',
  apiEndpoint: 'https://api.staging.trigonos.lumoe.io',
  webcamEndpoint: 'https://webcam-trigonos.dyndns.org:5003',
  trigonosGeoServerURL:
    'https://wms.trigonos.online/geoserver/gwc/service/wmts?service=WMTS&version=1.1.1&request=GetCapabilities',
};
