import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import {
  DataAvailableDateRange,
  DataService,
  InfluxOrthoDataPoint,
  ManagementService,
  SensorsSensor,
  SensorsService,
} from 'src/app/api/generated';
import { ReportModuleComponent } from 'src/app/report-container/report.component';
import { getTopLeft, getWidth, getCenter } from 'ol/extent.js';

import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';

import { Feature, Map as olMap, Overlay } from 'ol';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';
import TileLayer from 'ol/layer/Tile';
import { optionsFromCapabilities } from 'ol/source/WMTS';
import View from 'ol/View';

import { get as getProjection } from 'ol/proj.js';

import { platformModifierKeyOnly } from 'ol/events/condition';
import { createEmpty, extend } from 'ol/extent';
import { Point } from 'ol/geom';
import { defaults, DragPan, MouseWheelZoom } from 'ol/interaction';
import { Image as ImageLayer, Tile, Vector as VectorLayer } from 'ol/layer';
import { ImageWMS, Vector as SourceVector, TileWMS } from 'ol/source';
import { Fill, Icon, Style, Text } from 'ol/style';

import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import proj4 from 'proj4';
import { environment } from 'src/environments/environment';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DatePipe, DecimalPipe } from '@angular/common';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { merge, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ModuleConfiguration } from 'src/app/app.module';
import { ReportModulesService } from 'src/app/services/report-modules/report-modules.service';
import { UserService } from 'src/app/user.service';
import { GenericOrthofotoConfigComponent } from '../config/orthofoto-config.component';
import { OrthofotoService } from '../orthofoto.service';
import {
  circleSVG,
  generateSVG,
  getBahnachseFeature,
  largestValueInDataPoint,
  OrthofotoConfigGeneric,
} from '../orthophoto.tools';

interface CenterPoint {
  feature: Feature;
  style: Style;
}

interface DataPointConfig {
  loadedData?: InfluxOrthoDataPoint;
  centerPoint?: CenterPoint;
  vectorLayer?: VectorLayer;
  vectorSource?: SourceVector;
}

@Component({
  selector: 'orthofoto-module-generic',
  templateUrl: './orthofoto.component.html',
  styleUrls: ['./orthofoto.component.scss'],
  animations: [
    trigger('visibilityChanged', [
      state('shown', style({ opacity: 1, display: 'block' })),
      state('hidden', style({ opacity: 0, display: 'none' })),
      transition('shown => hidden', animate('600ms')),
      transition('hidden => shown', animate('0ms')),
    ]),
  ],
})
export class GenericOrthofotoComponent
  implements OnInit, AfterViewInit, OnDestroy, ReportModuleComponent {
  @Input() config: OrthofotoConfigGeneric;
  @ViewChild('mapElement') mapElement: ElementRef;
  @ViewChild('popUpContent') popUpContent: ElementRef;
  @ViewChild('popUpContainer') popUpContainer: ElementRef;
  @ViewChild('datepicker', { static: true }) datepicker: ElementRef;

  public static moduleDetails: ModuleConfiguration = {
    displayName: '[NEU] Vektorgraphik',
    previewImagePath: 'assets/orthophoto_preview.png',
    settingsComponent: GenericOrthofotoConfigComponent,
  };

  private datasets = new Map<string, DataPointConfig>();

  private map: olMap;
  private mapIsReady = false;
  public referenceScaleSize = 180;
  private loadingDatasetsComplete = false;
  orthofotoForm: UntypedFormGroup;

  public largestValue = 0.0001;
  public largestValueRoundUp = 0;

  private mapExtent = createEmpty();

  public maxDate = new Date(Date.now());
  public minDate = new Date(0);
  public minDates: Map<string, Date> = new Map();
  public maxDates: Map<string, Date> = new Map();

  public isLoading = true;

  layers = [
    { value: 'image', viewValue: 'Orthofoto' },
    { value: 'shading', viewValue: 'Schummerung' },
  ];

  public controlErrorMessage = {
    debounceTimer: new Date().getTime(),
    status: 'hidden',
    debounceCheckInterval: setInterval(() => {
      if (
        new Date().getTime() - this.controlErrorMessage.debounceTimer >
        1000
      ) {
        this.controlErrorMessage.status = 'hidden';
      }
    }, 700),
  };

  public layerControl = new UntypedFormControl(this.layers[0].value);

  constructor(
    private readonly dataService: DataService,
    private ngZone: NgZone,
    private _snackBar: MatSnackBar,
    private orthofotoService: OrthofotoService,
    private formBuilder: UntypedFormBuilder,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    public readonly reportModuleService: ReportModulesService,
    private managementService: ManagementService,
    public userService: UserService,
    private sensorsService: SensorsService
  ) { }

  private availableFields: Map<string, SensorsSensor> = new Map<
    string,
    SensorsSensor
  >();

  public minMaxDates: DataAvailableDateRange = undefined;

  public async loadData(initialLoading: boolean) {
    let allRequests = [];

    if (initialLoading) {
      // Load Field Information
      const sensorFieldInfoRequests = this.config.datasources
        .filter((station) => station.checked)
        .map((field) =>
          this.sensorsService.sensorsSensorRead(field.id).pipe(
            tap((result) => {
              this.availableFields.set(field.id, result);
            })
          )
        );

      allRequests.push(...sensorFieldInfoRequests);

      // Load date ranges
      const dateRangeRequests = this.config.datasources
        .filter((sensor) => sensor.checked)
        .map((sensor) => {
          return this.dataService
            .dataDaterangeInfluxList(sensor.id, 'd2d')
            .pipe(
              tap((result) => {
                result = {
                  start_date: new Date(result.start_date),
                  end_date: new Date(result.end_date),
                } as any;

                if (this.minMaxDates === undefined) {
                  this.minMaxDates = result;
                } else {
                  if (result.end_date > this.minMaxDates.end_date) {
                    this.minMaxDates.end_date = result.end_date;
                  }
                  if (result.start_date < this.minMaxDates.start_date) {
                    this.minMaxDates.start_date = result.start_date;
                  }
                }
              })
            );
        })
        .flat();

      allRequests.push(...dateRangeRequests);
    }

    const dataRequests = this.dataService
      .dataOrthophotoList(
        this.config.datasources
          .filter((station) => station.checked)
          .map((station) => station.id),
        this.getStartEndDate().startDate.toISOString(),
        this.getStartEndDate().endDate.toISOString()
      )
      .pipe(
        tap((result) => {
          for (let dataset of result) {
            if (!this.datasets.has(dataset.influx_name)) {
              this.datasets.set(dataset.influx_name, {});
            }

            this.datasets.get(dataset.influx_name).loadedData = dataset;

            const largestValueTmp = largestValueInDataPoint(
              this.datasets.get(dataset.influx_name).loadedData
            );
            if (largestValueTmp > this.largestValue) {
              this.largestValue = largestValueTmp;
            }
          }
        })
      );

    allRequests.push(dataRequests);

    const allRequestsHandled = allRequests.map((req$) =>
      req$.pipe(
        catchError((err) => {
          // Probably fix this in the future
          return of(null);
        })
      )
    );

    const merged$ = merge(...allRequestsHandled);
    merged$.subscribe({
      complete: () => {
        console.log('done');
        console.log(this.minMaxDates);
        this.orthofotoForm.controls['filterDateStart'].setValue(
          this.minMaxDates.start_date
        );
        this.orthofotoForm.controls['filterDateEnd'].setValue(
          this.minMaxDates.end_date
        );

        this.isLoading = false;
        console.log(this.datasets);
        this.awaitMapReady();
      },
    });
  }

  private getStartEndDate(): { startDate: Date; endDate: Date } {
    const startDate = new Date(
      this.orthofotoForm.controls['filterDateStart'].value
    );
    const endDate = new Date(
      this.orthofotoForm.controls['filterDateEnd'].value
    );

    return { startDate, endDate };
  }

  ngOnInit(): void {
    this.referenceScaleSize =
      this.referenceScaleSize * (this.config.scale_size_factor / 100);
    this.orthofotoForm = this.formBuilder.group({
      filterDateStart: [new Date(0), [Validators.required]],
      filterDateEnd: [new Date(Date.now()), [Validators.required]],
      layer: this.layerControl,
    });
    this.loadData(true);
  }

  private awaitMapReady() {
    const fetchDataPromises = {};

    let xTotal = 0,
      yTotal = 0;
    // Draw point to map
    for (let configDataset of this.config.datasources) {
      if (!configDataset.checked) {
        continue;
      }
      this.addDataPointToMap(configDataset.id);
      const dataset = this.datasets.get(configDataset.id).loadedData;
      xTotal += dataset.x;
      yTotal += dataset.y;
    }

    const centerX =
      xTotal /
      this.config.datasources.filter((datasource) => datasource.checked).length;
    const centerY =
      yTotal /
      this.config.datasources.filter((datasource) => datasource.checked).length;

    this.map.setView(
      new View({
        center: proj4(
          this.reportModuleService.currentTransformationTo,
          this.reportModuleService.currentTransformationFrom
        ).inverse([centerY, centerX]),
        zoom: 17,
      })
    );
  }

  ngAfterViewInit(): void {
    if (!this.map) {
      this.ngZone.runOutsideAngular(() => this.initMap());
    }
  }

  private async initMap() {
    const parser = new WMTSCapabilities();

    const trigonosCapabilities = await fetch(
      environment.trigonosGeoServerURL,
      {}
    );

    const responseText = await trigonosCapabilities.text();
    const trigonosCapabilitiesParsed = parser.read(responseText);

    const trigonosLayers = trigonosCapabilitiesParsed.Contents.Layer; //.filter(layer => layer.Identifier.includes('Trigonos'));

    const trigonosTileLayers = trigonosLayers.map(layer => new TileLayer({
      name: layer.Identifier,
      title: layer.Title,
      opacity: 1,
      source: new WMTS(optionsFromCapabilities(trigonosCapabilitiesParsed, {
        layer: layer.Identifier,
      })),
      type: 'base',
      visible: true,
    }));

    fetch(environment.basemapURL)
      .then((res) => {
        return res.text();
      })
      .then((capabilitiesText) => {
        const parser = new WMTSCapabilities();
        const capabilities = parser.read(capabilitiesText);
        const optionsImageLayer = optionsFromCapabilities(capabilities, {
          layer: 'bmaporthofoto30cm',
          matrixSet: 'EPSG:3857',
          style: 'raster',
        });

        const optionsGelaendeLayer = optionsFromCapabilities(capabilities, {
          layer: 'bmapgelaende',
          matrixSet: 'EPSG:3857',
        });

        this.map = new olMap({
          layers: [
            new TileLayer({
              name: 'shading',
              title: 'Gelände',
              opacity: 1,
              source: new WMTS(optionsGelaendeLayer),
              type: 'base',
            }),
            new TileLayer({
              name: 'image',
              title: 'Orthofoto',
              opacity: 1,
              source: new WMTS(optionsImageLayer),
              type: 'base',
            }),
            // Add Trigonos Layer
            ...trigonosTileLayers,
          ],
          target: this.mapElement.nativeElement,
          view: new View({
            // center: [1577650, 6038030],
            center: [0, 0],
            zoom: 19,
          }),
          controls: [],
          interactions: defaults({
            dragPan: false,
            mouseWheelZoom: false,
          }).extend([
            new DragPan({
              condition: function (event) {
                return (
                  this.getPointerCount() === 2 ||
                  'ontouchstart' in window === false
                );
              },
            }),
            new MouseWheelZoom({
              condition: this.checkKeyBoardModifierOrAddMessage,
            }),
          ]),
        });
        this.mapIsReady = true;

        const content = this.popUpContent;
        const container = this.popUpContainer;

        const popup = new Overlay({
          element: container.nativeElement,
          autoPan: true,
          autoPanAnimation: {
            duration: 250,
          },
        });
        this.map.addOverlay(popup);
        this.map.on('click', (ev) => {
          const feature = this.map.forEachFeatureAtPixel(
            ev.pixel,
            function (feat, layer) {
              return feat;
            }
          );

          if (
            feature?.values_.desc &&
            feature.values_.geometry instanceof Point
          ) {
            const coordinate = ev.coordinate;

            content.nativeElement.innerHTML = feature.get('desc');
            popup.setPosition(coordinate);
          } else {
            popup.setPosition(undefined);
          }
        });

        this.map.on('pointermove', (ev) => {
          const feature = this.map.forEachFeatureAtPixel(
            ev.pixel,
            function (feat, layer) {
              return feat;
            }
          );

          if (
            feature?.values_.desc &&
            feature.values_.geometry instanceof Point
          ) {
            const coordinate = ev.coordinate;

            content.nativeElement.innerHTML = feature.get('desc');
            popup.setPosition(coordinate);
          } else {
            popup.setPosition(undefined);
          }
        });

        this.map.on('wheel', (ev) => {
          if (!this.checkKeyBoardModifierOrAddMessage(ev)) {
            this.controlErrorMessage.status = 'shown';
            this.controlErrorMessage.debounceTimer = new Date().getTime();
          }
        });
      });
  }

  private checkKeyBoardModifierOrAddMessage = (event) => {
    if (platformModifierKeyOnly(event)) {
      return true;
    }
    // Ignore event
    if (event.type === 'pointermove') {
      return false;
    }
    return false;
  };

  private addDataPointToMap(datasetName: string) {
    if (!this.datasets.has(datasetName)) {
      return;
    }

    const dataset = this.datasets.get(datasetName);

    if (!dataset.vectorSource) {
      dataset.vectorSource = new SourceVector({
        features: [],
      });
    }

    if (!dataset.vectorLayer) {
      dataset.vectorLayer = new VectorLayer({
        source: dataset.vectorSource,
        zIndex: 99,
      });
      this.map.addLayer(dataset.vectorLayer);
    }

    dataset.vectorSource.once('change', (event) => {
      if (dataset.vectorSource.getExtent()) {
        extend(this.mapExtent, dataset.vectorSource.getExtent());
        if (this.datasets.size === 1) {
          this.map.setView(
            new View({
              center: proj4(
                this.reportModuleService.currentTransformationTo,
                this.reportModuleService.currentTransformationFrom
              ).inverse([dataset.loadedData.y, dataset.loadedData.x]),
              zoom: 18,
            })
          );
        } else {
          this.map.getView().fit(this.mapExtent, {
            size: this.map.getSize(),
            padding: [100, 100, 100, 100],
          });
        }
      }
      this.map.updateSize();
    });

    this.drawIconFeatures(datasetName);
  }

  private async drawIconFeatures(datasetName: string) {
    const dataset = this.datasets.get(datasetName);
    const centerPoint = dataset.loadedData;

    // Delete previous features
    for (let feature of dataset.vectorSource.getFeatures()) {
      dataset.vectorSource.removeFeature(feature);
    }

    const centerPointCoords = proj4(
      this.reportModuleService.currentTransformationTo,
      this.reportModuleService.currentTransformationFrom
    ).inverse([centerPoint.y, centerPoint.x]);

    const datasetDetailName =
      this.availableFields.get(datasetName).display_name;

    // Compute hours between two dates
    const diffInMS =
      new Date(dataset.loadedData.epoch).getTime() -
      new Date(Date.now()).getTime();

    // Milliseconds to hours
    const diffInHours = Math.abs(diffInMS / 1000 / 60 / 60);

    let colorCenterPoint = this.config.dynamic_center_point.color_ok;
    let warningMessage = ``;
    if (diffInHours > this.config.dynamic_center_point.difference_hours) {
      colorCenterPoint = this.config.dynamic_center_point.color_warning;
      warningMessage = `<br /> <p style="color: red">
            Letzter Messzeitpunkt liegt ${Math.round(
        diffInHours
      )} Stunden zurück. </p>`;
    }

    /**
     * Draw Center Point
     */
    const iconFeature = new Feature({
      geometry: new Point(centerPointCoords),
      desc:
        `<h4>${datasetDetailName}</h4> <br />

            <b>Verschiebung Höhe:</b> ${this.decimalPipe.transform(
          centerPoint.dz,
          '1.2-3',
          'de-AT'
        )}m<br />
            <b>Verschiebung 2D</b> ${this.decimalPipe.transform(
          centerPoint.d2d,
          '1.2-3',
          'de-AT'
        )}m<br />
            <b>Messzeitpunkt</b> ${this.datePipe.transform(
          centerPoint.epoch,
          'short'
        )}
            ` + warningMessage,
    });

    const centerPointStyle = new Style({
      image: new Icon({
        opacity: 1,
        src: 'data:image/svg+xml;utf8,' + circleSVG(colorCenterPoint),
        scale: 1,
      }),
      text: new Text({
        text: datasetDetailName,
        backgroundFill: new Fill({
          color: 'rgba(255, 255, 255, 0.63)',
        }),
        padding: [2, 2, 2, 2],
        offsetX: 10,
        offsetY: 20,
        font: '0.8rem sans-serif',
        textAlign: 'start',
      }),
    });

    /**
     * Draw XY Displacement Vectotr
     */

    const scaleFactorX = centerPoint.dx / this.largestValue;
    const scaleFactorY = centerPoint.dy / this.largestValue;
    const xyDisplacementVectorSVG = generateSVG(
      (this.referenceScaleSize / 2) * scaleFactorY,
      -1 * (this.referenceScaleSize / 2) * scaleFactorX,
      this.config.color_2d_vector,
      this.referenceScaleSize
    );

    const xyDisplacementVector = new Style({
      image: new Icon({
        opacity: 1,
        src: 'data:image/svg+xml;utf8,' + xyDisplacementVectorSVG,
        scale: 1,
        color: this.config.color_2d_vector,
      }),
    });

    const scaleFactor = -1 * (centerPoint.dz / this.largestValue);
    const displacementVector = generateSVG(
      0,
      (this.referenceScaleSize / 2) * scaleFactor,
      this.config.color_height_vector,
      this.referenceScaleSize
    );

    /**
     * Draw Height Displacement Vectotr
     */
    const heightDisplacementVector = new Style({
      image: new Icon({
        opacity: 1,
        src: 'data:image/svg+xml;utf8,' + displacementVector,
        scale: 1,
        color: this.config.color_height_vector,
      }),
    });

    iconFeature.setStyle([
      xyDisplacementVector,
      heightDisplacementVector,
      centerPointStyle,
    ]);
    dataset.vectorSource.addFeature(iconFeature);

    /**
     * Draw Bahnachsen
     */
    for (let bahnachse of this.config.axis) {
      let bahnachseFeature = getBahnachseFeature(
        this.transform(bahnachse.x1, bahnachse.y1),
        this.transform(bahnachse.x2, bahnachse.y2),
        bahnachse.text
      );
      dataset.vectorSource.addFeature(bahnachseFeature);
    }
  }

  private transform(x: Number, y: Number): Number[] {
    return proj4(
      this.reportModuleService.currentTransformationTo,
      this.reportModuleService.currentTransformationFrom
    ).inverse([x, y]);
  }

  /**
   * Swap visibility of layers
   * @param event
   */
  public changeVisibleLayer(event) {
    this.map.getLayers().forEach((layer) => {
      if (layer instanceof TileLayer) {
        if (event.value.includes('shading') && !layer.get('name').includes('shading')) {
          layer.setVisible(false);
        } else {
          layer.setVisible(true);
        }
      }
    });
  }

  /**
   * Click event for selecting the complete available timespan
   * in the calendar component
   * @param event
   * @param datePickerClose Reference to the close button
   */
  public selectCompleteTimespan(event, datePickerClose: MatButton) {
    this.orthofotoForm.controls['filterDateStart'].setValue(new Date(0));
    this.orthofotoForm.controls['filterDateEnd'].setValue(new Date());

    // Reset and reload the component
    if (this.orthofotoForm.valid) {
      this.loadingDatasetsComplete = false;
      this.largestValue = 0.00001;
      this.loadData(true);
    }

    // Close the daterange picker
    datePickerClose._elementRef.nativeElement.click();
  }

  public dateFilterChanged() {
    if (this.orthofotoForm.valid) {
      this.loadingDatasetsComplete = false;
      this.largestValue = 0.00001;
      this.minMaxDates.start_date =
        this.orthofotoForm.controls['filterDateStart'].value;
      this.minMaxDates.end_date =
        this.orthofotoForm.controls['filterDateEnd'].value;

      this.loadData(false);
    }
  }

  get referenceScaleWidth(): number {
    if (this.largestValue > 0) this.largestValueRoundUp = 0.001;
    if (this.largestValue > 0.001) this.largestValueRoundUp = 0.002;
    if (this.largestValue > 0.002) this.largestValueRoundUp = 0.005;
    if (this.largestValue > 0.005) this.largestValueRoundUp = 0.01;
    if (this.largestValue > 0.01) this.largestValueRoundUp = 0.02;
    if (this.largestValue > 0.02) this.largestValueRoundUp = 0.05;
    if (this.largestValue > 0.05) this.largestValueRoundUp = 0.1;
    if (this.largestValue > 0.1) this.largestValueRoundUp = 0.2;
    if (this.largestValue > 0.2) this.largestValueRoundUp = 0.5;
    if (this.largestValue > 0.5) this.largestValueRoundUp = 1;
    if (this.largestValue > 1) this.largestValueRoundUp = 2;
    if (this.largestValue > 2) this.largestValueRoundUp = 5;
    if (this.largestValue > 5) this.largestValueRoundUp = 10;
    if (this.largestValue > 10) this.largestValueRoundUp = 20;

    return (
      (this.referenceScaleSize * this.largestValueRoundUp) / this.largestValue
    );
  }

  get referenceScaleValues(): Array<Number> {
    let numElements = 0;
    if (this.largestValueRoundUp < 0.05) {
      numElements = 4;
    } else {
      numElements = 5;
    }
    let values = new Array<Number>();
    for (
      let x = this.largestValueRoundUp / numElements;
      x <= this.largestValueRoundUp;
      x += this.largestValueRoundUp / numElements
    ) {
      values.push(x);
    }
    return values;
  }

  ngOnDestroy() { }
}
